import React from 'react';

// Material UI
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface Props {
  title: string;
  expanded: string;
  setExpanded: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
  // icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
}

const SideBarExpansionPanel: React.FC<Props> = ({
  title,
  expanded,
  setExpanded,
  className,
  children,
}) => {
  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : '');
  };

  return (
    <Accordion
      expanded={expanded === title}
      onChange={handleChange(title)}
      className={className}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default SideBarExpansionPanel;
