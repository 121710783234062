import React, { useState, useEffect } from 'react';

// Material UI
import { Grid, TextField, LinearProgress } from '@material-ui/core';

// Custom Components
import SearchBarAutoComplete from './searchbar-autocomplete.component';
import SearchBarDatePicker from './searchbar-datepicker.component';
import SearchBarAutoCompleteTags from './searchbar-autocomplete-tags.component';

// Interfaces
import {
  SearchResults,
  SearchCriteria,
} from '../../interfaces/search.interfaces';
import { getListingBySearchCriteria } from '../../realm/webooks';
import { useRealmAuth } from '../../providers/realm-auth.provider';

interface Props {
  setSearchResults: React.Dispatch<React.SetStateAction<SearchResults>>;
}

const InitSearchCriteria: SearchCriteria = {
  location: localStorage.getItem('selectedLocation') || 'New York',
  position: { lat: 0, lng: 0 },
  dist: 2000,
  checkInDate:
    sessionStorage.getItem('selectedCheckinDate') ||
    new Date().toLocaleDateString('en-US'),
  numNights: parseInt(sessionStorage.getItem('selectedNumNights') || '2', 10),
  numGuests: parseInt(sessionStorage.getItem('selectedNumGuests') || '1', 10),
  keywords: [],
  cc: localStorage.getItem('cc') || 'US',
};

const getMapData = (location: string) =>
  fetch(
    `https://geocode.search.hereapi.com/v1/geocode?q=${location}&apiKey=JfH-gN4sNc1jBu63de2lcc2GNQJ9LNF__meIxvdH2xg`
  )
    .then((r) => r.json())
    .then((r) => {
      const { title, position, address } = r.items[0];
      return { title, position, cc: address.countryCode };
    });

const SearchBar = ({ setSearchResults }: Props) => {
  const { currentUser } = useRealmAuth();

  const [isSearching, setIsSearching] = useState(false);

  const {
    location,
    checkInDate,
    numNights,
    numGuests,
    keywords,
  } = InitSearchCriteria;

  const [selectedLocation, setSelectedLocation] = useState(location);
  const [selectedCheckinDate, setSelectedCheckinDate] = useState(checkInDate);
  const [selectedNumNights, setSelectedNumNights] = useState(numNights);
  const [selectedNumGuests, setSelectedNumGuests] = useState(numGuests);
  const [selectedKeywords, setSelectedKeywords] = useState(keywords);

  useEffect(
    () => {
      setIsSearching(true);
      const mapData = getMapData(selectedLocation);

      mapData.then((data) => {
        localStorage.setItem('selectedLocationLat', data.position.lat);
        localStorage.setItem('selectedLocationLng', data.position.lng);
        localStorage.setItem('cc', data.cc);
      });

      const listings = mapData.then((data) =>
        getListingBySearchCriteria(currentUser?.id, {
          ...InitSearchCriteria,
          location: selectedLocation,
          checkInDate: selectedCheckinDate,
          numNights: selectedNumNights,
          numGuests: selectedNumGuests,
          keywords: selectedKeywords,
          position: data.position,
          cc: data.cc,
        })
      );

      Promise.all([mapData, listings]).then((values) => {
        setSearchResults({
          mapData: values[0],
          listings: values[1],
        });
        setIsSearching(false);
      });
    },
    // eslint-disable-next-line
    [
      selectedLocation,
      selectedCheckinDate,
      selectedNumNights,
      selectedNumGuests,
      selectedKeywords,
    ]
  );

  useEffect(() => {
    localStorage.setItem('selectedLocation', selectedLocation);
    sessionStorage.setItem('selectedCheckinDate', selectedCheckinDate);
    sessionStorage.setItem('selectedNumNights', selectedNumNights.toString());
    sessionStorage.setItem('selectedNumGuests', selectedNumGuests.toString());
  }, [
    selectedLocation,
    selectedCheckinDate,
    selectedNumNights,
    selectedNumGuests,
  ]);

  return (
    <Grid container alignItems="stretch">
      <Grid
        item
        container
        justify="center"
        spacing={2}
        className="tour-home-search-features"
      >
        <Grid item>
          <SearchBarAutoComplete setSelectedLocation={setSelectedLocation} />
        </Grid>
        <Grid item>
          <SearchBarDatePicker
            setSelectedCheckinDate={setSelectedCheckinDate}
          />
        </Grid>
        <Grid item>
          <TextField
            id="outlined-number"
            label="Nights"
            type="number"
            defaultValue={selectedNumNights}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: 1, max: 30 }}
            style={{ width: 70 }}
            variant="outlined"
            onChange={(e) =>
              setSelectedNumNights(parseInt(e.currentTarget.value, 10))
            }
          />
        </Grid>
        <Grid item>
          <TextField
            id="outlined-number"
            label="Guests"
            type="number"
            defaultValue={selectedNumGuests}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: 1, max: 20 }}
            variant="outlined"
            style={{ width: 70 }}
            onChange={(e) =>
              setSelectedNumGuests(parseInt(e.currentTarget.value, 10))
            }
          />
        </Grid>
        <Grid item>
          <SearchBarAutoCompleteTags
            setSelectedKeywords={setSelectedKeywords}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {isSearching && <LinearProgress />}
      </Grid>
    </Grid>
  );
};

export default SearchBar;
