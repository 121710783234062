import React, { useState, useContext, useEffect } from 'react';
import {
  Grid,
  FormControlLabel,
  Switch,
  Button,
  Card,
  CardContent,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';

import { useHistory } from 'react-router';
import { CustomThemeContext } from '../providers/custom-theme.provider';

import { setCustomUserData } from '../realm/webooks';
import { useRealmAuth } from '../providers/realm-auth.provider';

const SettingsPage = () => {
  const { currentUser } = useRealmAuth();
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem('mainTour')) {
      history.push('/');
    }
  }, [history]);

  const { enqueueSnackbar } = useSnackbar();

  const [phoneNumber, setPhoneNumber] = useState(
    localStorage.getItem('phone') || '15551234567'
  );

  const { currentTheme, setCurrentTheme } = useContext(CustomThemeContext);

  const [theme, setTheme] = useState(currentTheme);
  const toggleTheme = () => {
    const nextTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(nextTheme);
    setCurrentTheme(nextTheme);
  };

  const [preferredRegion, setPreferredRegion] = useState(
    localStorage.getItem('preferredRegion') || 'us-east-1'
  );

  const saveSettings = () => {
    localStorage.setItem('phone', phoneNumber);
    localStorage.setItem('preferredRegion', preferredRegion);
    setCustomUserData(currentUser?.id, {
      phone: phoneNumber,
      theme,
      preferredRegion,
    });
    enqueueSnackbar('Settings Saved!', {
      variant: 'success',
    });
  };

  return (
    <Grid container>
      <Grid item xs={2} />
      <Grid item xs={8} container justify="center">
        <Grid item>
          <Card style={{ margin: 'auto' }}>
            <CardContent>
              <Grid
                container
                direction="column"
                spacing={2}
                alignItems="center"
              >
                <Grid item>
                  <FormControlLabel
                    label="Dark mode"
                    control={
                      <Switch
                        checked={theme === 'dark'}
                        onClick={toggleTheme}
                      />
                    }
                  />
                </Grid>
                <Grid item>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Phone Number
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      startAdornment={
                        <InputAdornment position="start">+</InputAdornment>
                      }
                      labelWidth={100}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      AWS Region
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={preferredRegion}
                      onChange={(e) =>
                        setPreferredRegion(e.target.value as string)
                      }
                      labelWidth={90}
                    >
                      {awsRegions.map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    color="secondary"
                    onClick={() => {
                      localStorage.removeItem('mainTour');
                      localStorage.removeItem('sidebarTour');
                      window.location.reload();
                    }}
                  >
                    Reset Tutorial
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={saveSettings}
                  >
                    Save Settings
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};

export default SettingsPage;

const awsRegions = [
  'us-east-1',
  'us-east-2',
  'us-west-1',
  'us-west-2',
  'ca-central-1',
  'sa-east-1',
  'eu-north-1',
  'eu-west-1',
  'eu-west-2',
  'eu-west-3',
  'eu-central-1',
  'me-south-1',
  'ap-east-1',
  'ap-northeast-1',
  'ap-northeast-2',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-south-1',
];
