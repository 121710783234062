import React, { useEffect, useContext } from 'react';
import { StitchAuth } from 'mongodb-stitch-browser-sdk';

import {
  hasLoggedInUser,
  loginAnonymous,
  logoutCurrentUser,
  loginGoogle,
  getCurrentUser,
  addAuthenticationListener,
  removeAuthenticationListener,
  handleOAuthRedirects,
} from '../realm/authentication';

import { CustomThemeContext } from './custom-theme.provider';

const INITIAL_VALUE = {
  isLoggedIn: hasLoggedInUser(),
  currentUser: getCurrentUser(),
  actions: {
    handleLogin: (provider: string) => {},
    handleLogout: () => {},
  },
};

// Create a React Context that lets us expose and access auth state
// without passing props through many levels of the component tree
const RealmAuthContext = React.createContext(INITIAL_VALUE);

// Create a React Hook that lets us get data from our auth context
export function useRealmAuth() {
  const context = React.useContext(RealmAuthContext);
  if (!context) {
    throw new Error('useRealmAuth must be used within a RealmAuthProvider');
  }
  return context;
}

export const RealmAuthProvider: React.FC = (props) => {
  const [authState, setAuthState] = React.useState(INITIAL_VALUE);

  const { setCurrentTheme } = useContext(CustomThemeContext);

  useEffect(() => {
    const authListener = {
      onUserLoggedIn: (authUser: StitchAuth) => {
        if (authUser?.user) {
          setAuthState((state) => ({
            ...state,
            isLoggedIn: true,
            currentUser: authUser.user,
          }));
        }

        const customData = authUser.user?.customData;

        if (customData?.phone) {
          if (!localStorage.getItem('phone')) {
            localStorage.setItem('phone', customData.phone);
          }
        }

        if (customData?.theme) {
          if (!localStorage.getItem('theme')) {
            setCurrentTheme(customData.theme);
          }
        }

        if (customData?.preferredRegion) {
          if (!localStorage.getItem('preferredRegion')) {
            localStorage.setItem('preferredRegion', customData.preferredRegion);
          }
        }
      },
      onUserLoggedOut: () => {
        setAuthState((state) => ({
          ...state,
          isLoggedIn: false,
          currentUser: null,
        }));
      },
    };
    addAuthenticationListener(authListener);
    handleOAuthRedirects();
    setAuthState((state) => ({ ...state }));
    return () => {
      removeAuthenticationListener(authListener);
    };
  }, [setCurrentTheme]);

  const handleLogin = async (provider: string) => {
    if (!authState.isLoggedIn) {
      switch (provider) {
        case 'anonymous':
          return loginAnonymous();
        case 'google':
          return loginGoogle();
        default:
          return false;
      }
    }
    return false;
  };

  const handleLogout = async () => {
    const { isLoggedIn } = authState;
    if (isLoggedIn) {
      await logoutCurrentUser();
      setAuthState({
        ...authState,
        isLoggedIn: false,
        currentUser: null,
      });
    } else {
      // console.log("can't handleLogout when no user is logged in");
    }
  };

  // We useMemo to improve performance by eliminating some re-renders
  const authInfo = React.useMemo(() => {
    const { isLoggedIn, currentUser } = authState;
    return { isLoggedIn, currentUser };
  }, [authState]);
  return (
    <RealmAuthContext.Provider
      value={{ ...authInfo, actions: { handleLogin, handleLogout } }}
    >
      {props.children}
    </RealmAuthContext.Provider>
  );
};
RealmAuthProvider.propTypes = {};
