import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from '@material-ui/core';

import CreditCardIcon from '@material-ui/icons/CreditCard';
import KingBedOutlinedIcon from '@material-ui/icons/KingBedOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import BathtubOutlinedIcon from '@material-ui/icons/BathtubOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 151,
    },
    chip: {
      marginRight: 5,
    },
  })
);

interface Props {
  name: string;
  pictureUrl: string;
  cost: string;
  bedrooms: string;
  bathrooms: string;
  accomodates: string;
}

const ListingCard: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { name, pictureUrl, cost, bedrooms, bathrooms, accomodates } = props;

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.cover} image={pictureUrl} title={name} />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant="subtitle1" color="textSecondary">
            {name.length > 30 ? `${name.slice(0, 30)}...` : name}
          </Typography>
          <Chip
            className={classes.chip}
            icon={<CreditCardIcon />}
            label={cost}
          />
          <Chip
            className={classes.chip}
            icon={<KingBedOutlinedIcon />}
            label={bedrooms}
          />
          <Chip
            className={classes.chip}
            icon={<PeopleOutlineIcon />}
            label={bathrooms}
          />
          <Chip
            className={classes.chip}
            icon={<BathtubOutlinedIcon />}
            label={accomodates}
          />
        </CardContent>
      </div>
    </Card>
  );
};

export default ListingCard;
