import React, { useEffect } from 'react';

// Material UI
import { Grid, Typography } from '@material-ui/core';

// Contexts
import { useAuditLog } from '../providers/audit-log.provider';

// Custom Components
import MyBookingsTable from '../components/mybookings-table.component';
import TeachingBubble from '../components/teaching-bubble.component';

const MyBookingsPage = () => {
  const { addLogEntry } = useAuditLog();
  useEffect(() => {
    addLogEntry({
      action: 'Page View',
      page: 'My Bookings Page',
    });
  }, [addLogEntry]);

  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item>
        <Typography variant="h5">My Bookings</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <TeachingBubble
              label="What is location?"
              contentHeader="Data Locality"
              content="Did you know that with a few clicks of a button, you can enable Global Writes in Mongodb Atlas via sharding? Here the Location field is the shard key and determines to which cluster to write. This allows rules that govern Data Locality."
              capabilitySlug="/booking"
            />
          </Grid>
          <Grid item>
            <TeachingBubble
              label="What about archival data?"
              contentHeader="Data Lake and Archive Storage"
              content="Did you know that MongoDB Atlas has features called Data Lake and Online Archive? It allows you to have warm storage in a cloud's object storage, like S3, and query it as if it is in MongoDB Atlas clusters."
              capabilitySlug="/PLACEHOLDER_DL"
            />
          </Grid>
          <Grid item>
            <TeachingBubble
              label="How can I tell if data changes?"
              contentHeader="Change Streams"
              content="Change Streams allow applications to subscribe to data changes and filter for specific events using the Aggregation Framework. Try and book something and you will get a notification. That is powered by Change Streams!"
              capabilitySlug="/PLACEHOLDER_CHANGE-CAPTURE"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <MyBookingsTable />
      </Grid>
    </Grid>
  );
};

export default MyBookingsPage;
