import {
  AnonymousCredential,
  GoogleRedirectCredential,
} from 'mongodb-stitch-browser-sdk';
import { app } from './app';

export async function loginGoogle() {
  return app.auth.loginWithRedirect(
    new GoogleRedirectCredential(`${window.location.origin.toString()}/`)
  );
}

export function loginAnonymous() {
  // Allow users to log in anonymously
  const credential = new AnonymousCredential();
  return app.auth.loginWithCredential(credential);
}

export function hasLoggedInUser() {
  // Check if there is currently a logged in user
  return app.auth.isLoggedIn;
}

export function getCurrentUser() {
  // Return the user object of the currently logged in user
  return app.auth.isLoggedIn ? app.auth.user : null;
}

export function logoutCurrentUser() {
  // Logout the currently logged in user
  const user = getCurrentUser();
  return user ? app.auth.logoutUserWithId(user.id) : null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addAuthenticationListener(listener: any) {
  app.auth.addAuthListener(listener);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeAuthenticationListener(listener: any) {
  app.auth.removeAuthListener(listener);
}

export function handleOAuthRedirects() {
  if (app.auth.hasRedirectResult()) {
    return app.auth.handleRedirectResult();
  }
  return false;
}
