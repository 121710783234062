import React, { useReducer, useEffect } from 'react';
import JoyRide, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useTheme, Typography } from '@material-ui/core';

const TOUR_STEPS = [
  {
    target: '.tour-sidebar-main',
    title: 'Story Telling',
    content:
      'Everything you need to tell a story is documented inside the sidebar.',
    disableBeacon: true,
    placement: 'left-start',
    hideCloseButton: true,
  },
  {
    target: '.tour-sidebar-popout',
    title: 'Popout',
    content:
      'Click the title to open this SideBar into a new window. Great for Dual Monitors!',
    hideCloseButton: true,
  },
  {
    target: '.tour-sidebar-talk-track',
    title: 'Talk Track',
    content:
      'When learning how to demo a specific capability, this is where you find the initial talking points.',
    placement: 'left',
    hideCloseButton: true,
  },
  {
    target: '.tour-sidebar-technology',
    title: 'Technology Used',
    content:
      'To accomplish each capability, the required set of technology are listed here for your convenience.',
    expansion: 'foobar',
    placement: 'left',
    hideCloseButton: true,
  },
  {
    target: '.tour-sidebar-procedure',
    title: 'Demo Procedure',
    content: 'This section describes the intended flow of the demo.',
    placement: 'left',
    hideCloseButton: true,
  },
  {
    target: '.tour-sidebar-links',
    title: 'Additional Links',
    content: (
      <>
        <Typography>
          <b>Notebooks:</b> Self-guided code examples of the selected
          capability.
        </Typography>
        <Typography>
          <b>Web Shells:</b> Quickly connect to the Atlas cluster via the
          webshells! Federated Queries supported.
        </Typography>
        <Typography>
          <b>Connect:</b> Copy Connection String to clipboard, or navigate to
          the Atlas UI.
        </Typography>
        <Typography>
          <b>POV Source:</b> Link to original PoV
        </Typography>
        <Typography>
          <b>Capability List:</b> Quickly and easily change between different
          capabilities.
        </Typography>
      </>
    ),
    placement: 'left',
    hideCloseButton: true,
  },
];

const INITIAL_STATE = {
  key: new Date(), // This field makes the tour to re-render when we restart the tour
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
};

// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case 'START':
      return { ...state, run: true };
    case 'RESET':
      return { ...state, stepIndex: 0 };
    case 'STOP':
      return { ...state, run: false };
    case 'NEXT_OR_PREV':
      return { ...state, ...action.payload };
    case 'RESTART':
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};

interface Props {
  setExpanded: React.Dispatch<React.SetStateAction<string>>;
}

// Tour component
const SideBarTour = ({ setExpanded }: Props) => {
  const theme = useTheme();

  // Tour state is the state which control the JoyRide component
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    // Auto start the tour if the tour is not viewed before
    if (!localStorage.getItem('sidebarTour')) {
      dispatch({ type: 'START' });
    }
  }, []);

  // Set once tour is viewed, skipped or closed
  const setTourViewed = () => {
    localStorage.setItem('sidebarTour', '1');
  };

  const callback = (data: any) => {
    const { action, index, type, status } = data;

    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: 'STOP' });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.

      switch (index) {
        case 2:
          setExpanded('Technology');
          break;
        case 3:
          setExpanded('Procedure');
          break;
        case 4:
          setExpanded('Links');
          break;
      }

      setTimeout(() => {
        dispatch({
          type: 'NEXT_OR_PREV',
          payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
        });
      }, 200);
    }
  };

  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        showProgress
        showSkipButton={true}
        disableOverlayClose
        disableCloseOnEsc
        hideBackButton
        styles={{
          tooltipContainer: {
            textAlign: 'left',
          },
          buttonBack: {
            marginRight: 10,
          },
          options: {
            textColor: theme.palette.text.primary,
            primaryColor: theme.palette.primary.main,
            arrowColor: theme.palette.background.paper,
            backgroundColor: theme.palette.background.paper,
          },
        }}
        locale={{
          last: 'End tour',
        }}
      />
    </>
  );
};

export default SideBarTour;
