import React from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import { Link } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { SearchResults } from '../interfaces/search.interfaces';

interface ListingMapProps {
  searchResults: SearchResults;
}

const iconLeaf = new L.Icon({
  iconUrl: '/leaf.png',
  iconRetinaUrl: '/leaf.png',
  shadowUrl: '/leafshadow.png',
  shadowSize: new L.Point(31, 32),
  shadowAnchor: new L.Point(2, 16),
  iconSize: new L.Point(15, 32),
});

const ListingMap = ({ searchResults }: ListingMapProps) => {
  const { mapData, listings } = searchResults;

  const position: L.LatLngTuple = [mapData.position.lat, mapData.position.lng];

  const theme = useTheme();

  return (
    <Map center={position} zoom={14} style={{ height: '100%', zIndex: 1 }}>
      {theme.palette.type === 'light' ? (
        <TileLayer
          attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
          url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png?api_key=b66fd4ae-19ed-4099-9fd7-ed7a90033683"
        />
      ) : (
        <TileLayer
          attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
          url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=b66fd4ae-19ed-4099-9fd7-ed7a90033683"
        />
      )}

      {listings.map((item) => {
        return (
          <Marker
            icon={iconLeaf}
            key={item._id}
            position={[
              item.address.location.coordinates[1],
              item.address.location.coordinates[0],
            ]}
          >
            <Popup contentStyle={{ width: '201px', wordBreak: 'break-all' }}>
              <Link to={`/listing/${item._id}`}>
                <div>{item.name}</div>
                <br />
                <img src={item.images.picture_url} alt="img" width="200" />
              </Link>
            </Popup>
          </Marker>
        );
      })}
    </Map>
  );
};

export default ListingMap;
