import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

// Custom Components
import MaterialTable from 'material-table';

// Webhooks
import {
  getLastTwentyBookingsByUserId,
  deleteBookings,
} from '../realm/webooks';
import { useRealmAuth } from '../providers/realm-auth.provider';

interface Booking {
  _id: string;
  booking_cost: number;
  booking_number: number;
  checkin: Date;
  checkout: Date;
  listing_id: string;
  location: string;
}

const MyBookingsTable = () => {
  const { currentUser } = useRealmAuth();
  const [data, setData] = useState<Array<Booking>>([]);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (currentUser) {
      getLastTwentyBookingsByUserId(currentUser?.id).then((response) => {
        setData(response.bookings);
      });
    }
  }, [currentUser, reload]);

  return (
    <MaterialTable
      title={isMobile ? 'Bookings' : 'Recent Bookings'}
      columns={
        isMobile
          ? [
              { title: 'Location', field: 'location' },
              { title: 'Check In', field: 'checkin', type: 'date' },
              { title: 'Check out', field: 'checkout', type: 'date' },
            ]
          : [
              { title: 'Booking ID', field: '_id' },
              { title: 'Location', field: 'location' },
              { title: 'Check In', field: 'checkin', type: 'date' },
              { title: 'Check out', field: 'checkout', type: 'date' },
              { title: 'Guests', field: 'guests', type: 'numeric' },
              { title: 'Listing ID', field: 'listing_id' },
            ]
      }
      data={data}
      options={{
        selection: true,
      }}
      actions={[
        {
          tooltip: 'Cancel Selected bookings',
          icon: 'delete',
          onClick: (e, d) => {
            if (Array.isArray(d)) {
              const ids = d.map((b) => {
                return { _id: b._id, location: b.location };
              });
              deleteBookings(currentUser?.id, ids).then(() => {
                setReload((v) => !v);
              });
            }
          },
        },
      ]}
    />
  );
};

export default MyBookingsTable;
