import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// import MenuItem from '@material-ui/core/MenuItem';
import { Link, MenuItem } from '@material-ui/core';

import MenuBookIcon from '@material-ui/icons/MenuBook';
import MenuIcon from '@material-ui/icons/Menu';
import TrendingUpSharpIcon from '@material-ui/icons/TrendingUpSharp';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';

import DropDownButton from './dropdown-button.component';

const iconStyle = { marginRight: 4 };

const FeaturesMenuButton = () => {
  return (
    <DropDownButton label="Features" startIcon={<MenuIcon />}>
      <MenuItem
        component={Link}
        href="https://drive.google.com/file/d/1Ni3qN-n0-uXpV-iKipl49dHWyk1zvk9F/view?usp=drivesdk"
        color="inherit"
        target="__blank"
      >
        <EmojiObjectsOutlinedIcon style={iconStyle} /> Tutorial
      </MenuItem>
      <MenuItem component={RouterLink} to="/charts">
        <TrendingUpSharpIcon style={iconStyle} /> Charts
      </MenuItem>
      <MenuItem component={RouterLink} to="/notebooks">
        <MenuBookIcon style={iconStyle} /> Notebooks
      </MenuItem>
    </DropDownButton>
  );
};

export default FeaturesMenuButton;
