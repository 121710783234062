import React, { useEffect } from 'react';

// Material UI
import { Grid } from '@material-ui/core';

// Contexts
import { useAuditLog } from '../providers/audit-log.provider';

// Custom Components
import NotebooksTable from '../components/notebooks-table.component';

const NotebooksPage = () => {
  const { addLogEntry } = useAuditLog();
  useEffect(() => {
    addLogEntry({
      action: 'Page View',
      page: 'Notebooks Page',
    });
  }, [addLogEntry]);

  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid style={{ minWidth: '430px' }} item>
        <NotebooksTable />
      </Grid>
    </Grid>
  );
};

export default NotebooksPage;
