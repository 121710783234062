import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// Realm Web Hook Functions
import { createAuditLogEntry } from '../realm/webooks';

import { useRealmAuth } from './realm-auth.provider';

interface AuditLogEvent {
  user_id: string;
  email: string;
  action: string; // View/Query/Etc...
  ts: Date;
}

interface AuditLogs {
  auditLogs: AuditLogEvent[];
  addLogEntry: (addInfo: {}) => void;
}

const INITIAL_VALUE: Array<AuditLogEvent> = [];
const AuditLogContext = React.createContext<AuditLogs>({
  auditLogs: INITIAL_VALUE,
  addLogEntry: () => {},
});

// Custom React Hook for Component Consumption
export function useAuditLog() {
  const context = React.useContext(AuditLogContext);
  if (!context) {
    throw new Error('useAuditLog must be used within a AuditLogProvider');
  }
  return context;
}

// Context for app level state
export const AuditLogProvider: React.FC = (props) => {
  const { currentUser } = useRealmAuth();
  const [auditLogs, setAuditLogs] = React.useState(INITIAL_VALUE);

  // Update MongoDB for every Audit Action
  useEffect(() => {
    if (auditLogs[0]) {
      const latest = {
        ...auditLogs[auditLogs.length - 1],
        ...(currentUser && {
          user_id: currentUser?.id,
          email: currentUser?.profile.email,
        }),
      };

      // console.log(latest);

      createAuditLogEntry(currentUser?.id, latest);
    }
  }, [auditLogs, currentUser]);

  const addLogEntry = (addInfo: object) => {
    const newEntry: AuditLogEvent = {
      user_id: 'UNKNOWN',
      email: 'UNKNOWN',
      action: '', // View/Query/Etc...
      ts: new Date(),
      ...addInfo,
    };

    setAuditLogs((existing) => [...existing, newEntry]);
  };

  const addLogEntryCallback = useCallback(addLogEntry, []);

  return (
    <AuditLogContext.Provider
      value={{
        auditLogs,
        addLogEntry: addLogEntryCallback,
      }}
    >
      {props.children}
    </AuditLogContext.Provider>
  );
};

AuditLogProvider.propTypes = {
  children: PropTypes.element,
};

AuditLogProvider.defaultProps = {
  children: undefined,
};
