import React, { useState, useEffect } from 'react';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import isValid from 'date-fns/isValid';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface Props {
  setSelectedCheckinDate: React.Dispatch<React.SetStateAction<string>>;
}

function SearchBarDatePicker({ setSelectedCheckinDate }: Props) {
  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(
    new Date()
  );

  const [isLoading, setIsLoading] = useState(true);
  useEffect(
    () => {
      if (isLoading) {
        setIsLoading((v) => !v);
        return;
      }
      selectedDate &&
        isValid(selectedDate) &&
        setSelectedCheckinDate(selectedDate.toLocaleDateString('en-US'));
    },
    // eslint-disable-next-line
    [selectedDate]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        variant="inline"
        inputVariant="outlined"
        label="Check In Date"
        format="MM/dd/yyyy"
        value={selectedDate}
        InputAdornmentProps={{ position: 'start' }}
        onChange={(date) => {
          date && setSelectedDate(date);
        }}
        style={{ width: 180 }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default SearchBarDatePicker;
