import React, { useState, useEffect } from 'react';

// Custom Components
import MaterialTable from 'material-table';
import Link from '@material-ui/core/Link';

// Webhooks
import { getAllSideBarStories } from '../realm/webooks';
import { useRealmAuth } from '../providers/realm-auth.provider';

const shellHost = process.env.REACT_APP_NOTEBOOK_URL;

interface Story {
  name: string;
  Background: string;
  Talk_Track_Script: string;
  Procedure: string[];
  Tech_Involved: string[];
  Original_Source: string;
  Notebook_link: string;
}

const NotebooksTable = () => {
  const { currentUser } = useRealmAuth();
  const [data, setData] = useState<Array<Story>>([]);

  useEffect(() => {
    if (currentUser) {
      getAllSideBarStories(currentUser?.id).then((response) => {
        setData(response);
      });
    }
  }, [currentUser]);

  return (
    <MaterialTable
      title="Notebooks"
      columns={[
        { title: 'Name', field: 'name' },
        {
          title: 'Shared Notebook Link',
          field: 'Notebook_link',
          render: (rowData) => (
            <Link
              target="_blank"
              href={`${shellHost}:8888/notebooks${rowData.Notebook_link}?token=a63f5af6a3b7e503fd1e6ff1656701652cd974ca`}
            >
              Open Notebook
            </Link>
          ),
        },
      ]}
      data={data}
      options={{
        pageSize: 10,
        pageSizeOptions: [5, 10, 20, 50],
        emptyRowsWhenPaging: false,
      }}
    />
  );
};

export default NotebooksTable;
