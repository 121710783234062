import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useCapabilityContext } from '../../providers/capability.provider';

interface AutoCompleteSuggestion {
  _id: string;
}

const getSuggestions = (
  query: string
): Promise<Array<AutoCompleteSuggestion>> => {
  return fetch(
    `https://webhooks.mongodb-stitch.com/api/client/v2.0/app/mside-rqcve/service/http/incoming_webhook/getAutoCompleteCity?city=${query}`
  ).then((r) => r.json());
};

const INITIAL_MARKETS = ['New York', 'Montreal', 'Barcelona'];

interface Props {
  setSelectedLocation: React.Dispatch<React.SetStateAction<string>>;
}

const SearchBarAutoComplete = ({ setSelectedLocation }: Props) => {
  const { setCurrentCapability } = useCapabilityContext();

  const [markets, setMarkets] = useState(INITIAL_MARKETS);
  const [value, setValue] = React.useState<string | null>(
    localStorage.getItem('selectedLocation') || INITIAL_MARKETS[0]
  );
  const [inputValue, setInputValue] = React.useState('');

  const handleChange = (val: string) => {
    if (val && val !== '') {
      getSuggestions(val).then((r) => {
        setMarkets(r.map((item) => item._id));
      });
    }
  };

  return (
    <Autocomplete
      value={value}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(event: any, newValue: string | null, reason: string) => {
        setValue(newValue);
        if (reason !== 'clear') {
          setSelectedLocation(newValue || '');
          setCurrentCapability('/PLACEHOLDER-AUTO-COMPLETE');
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        handleChange(newInputValue);
      }}
      id="controllable-states-demo"
      options={markets}
      style={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} label="Location Search" variant="outlined" />
      )}
    />
  );
};

export default SearchBarAutoComplete;
