import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Material UI
import {
  Grid,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

// Contexts
import { useRealmAuth } from '../providers/realm-auth.provider';
import { useAuditLog } from '../providers/audit-log.provider';

// Webhooks
import { getListingByID, createBooking } from '../realm/webooks';

// Custom Components
import BookingCard from '../components/booking/booking-card.component';
import BookingPersonInfo from '../components/booking/booking-person-info.component';

interface BookingDetails {
  listing_id: string;
  booking_number: number;
  booking_cost: number;
  location: string;
  user_id: string;
  contact_info: {
    name: string | undefined;
    email: string | undefined;
  };
  checkin: Date;
  checkout: Date;
  guests: number;
}

const BookingPage = () => {
  const { id } = useParams();

  const { addLogEntry } = useAuditLog();
  useEffect(() => {
    addLogEntry({
      action: 'Page View',
      page: 'Booking Page',
      listing_id: id,
    });
  }, [addLogEntry, id]);

  const { currentUser } = useRealmAuth();

  function addDays(date: Date, days: number) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const numNights = sessionStorage.getItem('selectedNumNights') || '1';
  const numGuests = sessionStorage.getItem('selectedNumGuests') || '1';
  const cc = localStorage.getItem('cc') || 'US';

  const checkin =
    sessionStorage.getItem('selectedCheckinDate') ||
    new Date().toLocaleDateString('en-US');
  const checkout = addDays(
    new Date(checkin),
    parseInt(numNights, 10)
  ).toLocaleDateString('en-US');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [listingData, setListingData] = useState<any>({});

  const [dialogOpen, setDialogOpen] = useState(false);

  const [bookingResults, setBookingResults] = useState({ newid: '' });
  const [bookingData, setBookingData] = useState<BookingDetails>({
    listing_id: '',
    booking_cost: 0,
    booking_number: 0,
    location: 'US',
    user_id: '1234567890',
    contact_info: {
      name: '',
      email: '',
    },
    checkin: new Date(checkin),
    checkout: new Date(checkout),
    guests: parseInt(numGuests, 10),
  });

  const [isBooking, setIsBooking] = useState(false);
  const handleBooking = (data: BookingDetails) => {
    setIsBooking(true);
    createBooking(currentUser?.id, data)
      .then((response) => setBookingResults(response))
      .then(() => setDialogOpen(true))
      .then(() => {
        addLogEntry({ action: 'Create Booking', listing_id: id });
      })
      .finally(() => setIsBooking(false));
  };

  useEffect(() => {
    getListingByID(currentUser?.id, { id, cc }).then((response) => {
      setListingData(response);
    });
    addLogEntry({
      action: 'Page View',
      page: 'Listing Page',
      listing_id: id,
    });
  }, [id, currentUser, cc, addLogEntry]);

  useEffect(() => {
    if (currentUser) {
      setBookingData((b: BookingDetails) => ({
        ...b,
        listing_id: id,
        booking_cost:
          listingData.price *
          Math.round(
            (b.checkout.getTime() - b.checkin.getTime()) / (1000 * 60 * 60 * 24)
          ),
        booking_number: 0,
        user_id: currentUser.id,
        contact_info: {
          name: currentUser.profile.name,
          email: currentUser.profile.email,
          phone: localStorage.getItem('phone') || '15551234567',
        },
      }));
    }
  }, [id, currentUser, listingData]);

  return (
    <Grid container spacing={2} justify="center" alignContent="flex-start">
      {listingData.name ? (
        <>
          <Grid item xs={10} md={5}>
            <BookingCard
              title="Listing Information"
              subTitle={listingData.name}
              cardImage={listingData.images.picture_url}
              details={[
                { key: 'Address', value: listingData.address.street },
                { key: 'Country Code', value: listingData.location },
                ...(listingData.house_rule
                  ? [{ key: 'House Rules', value: listingData.house_rule }]
                  : []),
                ...(listingData.notes
                  ? [{ key: 'Notes', value: listingData.notes }]
                  : []),
              ]}
            />
          </Grid>
          <Grid item xs={10} md={5} container direction="column" spacing={2}>
            <Grid item>
              <BookingCard title="Host Information">
                <BookingPersonInfo
                  name={listingData.host.host_name}
                  picture_url={listingData.host.host_picture_url}
                  email={`${listingData.host.host_name
                    .toLowerCase()
                    .replace(/\W+/, '.')}@mside.com`}
                  phone="15551234567"
                />
              </BookingCard>
            </Grid>
            <Grid item>
              <BookingCard
                title="Booking Information"
                details={[
                  { key: 'Check In', value: `${checkin} at 3:00PM` },
                  { key: 'Check Out', value: `${checkout}  at 11:00AM` },
                  { key: 'Total Nights', value: `${numNights}` },
                  { key: 'Additional Guests', value: `${numGuests}` },
                  {
                    key: 'Cancellation Policy',
                    value: 'strict 14 with grace period',
                  },
                ]}
              />
            </Grid>
            <Grid item>
              <BookingCard title="Your Information">
                <BookingPersonInfo
                  name={currentUser?.profile.name || 'TBD'}
                  picture_url={currentUser?.profile.pictureUrl || ''}
                  email={currentUser?.profile.email}
                  phone={localStorage.getItem('phone') || '15551234567'}
                />
              </BookingCard>
            </Grid>
          </Grid>
          <Grid item xs={12} container justify="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={isBooking}
                onClick={() => handleBooking(bookingData)}
              >
                {isBooking ? 'Creating Booking' : 'Confirm Booking'}
              </Button>
              <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle id="alert-dialog-slide-title">
                  Booking Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Congratulations! You're officially booked!
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-slide-description">
                    Be sure to check your email for the confirmation number and
                    details!
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-slide-description">
                    Your reservation ID is: {bookingResults.newid}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDialogOpen(false)} color="primary">
                    Done
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </>
      ) : (
        <CircularProgress />
      )}
    </Grid>
  );
};

export default BookingPage;
