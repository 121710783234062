import React from 'react';
import {
  Grid,
  Avatar,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    large: {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
  })
);

interface Props {
  name: string;
  picture_url: string;
  email?: string;
  phone?: string;
}

const BookingPersonInfo = ({ name, picture_url, email, phone }: Props) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <Avatar alt={name} src={picture_url} className={classes.large} />
      </Grid>
      <Grid item>
        <Typography>
          <b>Name:</b> {name}
        </Typography>
        {email && (
          <Typography>
            <b>Email:</b> {email}
          </Typography>
        )}
        {phone && (
          <Typography>
            <b>Phone:</b> {phone}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

BookingPersonInfo.defaultProps = {
  email: undefined,
  phone: undefined,
};

export default BookingPersonInfo;
