import React from 'react';

// Material UI
import { Card } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const ListingSearchSkeleton = () => {
  return (
    <Card>
      <Skeleton variant="rect" animation="wave" width={480} height={100} />
    </Card>
  );
};

export default ListingSearchSkeleton;
