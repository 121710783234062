import React from 'react';
import {
  Typography,
  Divider,
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardContent,
  CardMedia,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    large: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    media: {
      height: 300,
    },
  })
);

interface DetailType {
  key: string;
  value: string;
}

interface Props {
  title: string;
  subTitle?: string;
  cardImage?: string;
  details?: DetailType[];
}

const BookingCard: React.FC<Props> = ({
  children,
  title,
  subTitle,
  cardImage,
  details,
}) => {
  const classes = useStyles();

  return (
    <Card variant="outlined">
      {cardImage && <CardMedia className={classes.media} image={cardImage} />}
      <CardContent>
        <Typography variant="h5">{title}</Typography>
        <Divider />
        <Typography variant="h6">{subTitle}</Typography>
        <br />
        {details?.map((detail, index) => {
          return (
            <Typography key={index}>
              <b>{detail.key}:</b> {detail.value}
            </Typography>
          );
        })}
        {children}
      </CardContent>
    </Card>
  );
};

export default BookingCard;
