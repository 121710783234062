import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Popper,
  ClickAwayListener,
} from '@material-ui/core';
import { useCapabilityContext } from '../providers/capability.provider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    root: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
  })
);

interface Props {
  label: string;
  content: string;
  contentHeader: string;
  capabilitySlug?: string;
  handleContinue?: () => void;
}

const TeachingBubble = ({
  label,
  contentHeader,
  content,
  capabilitySlug,
  handleContinue,
}: Props) => {
  const { setCurrentCapability } = useCapabilityContext();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    capabilitySlug && setCurrentCapability(capabilitySlug);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Button
          aria-describedby={id}
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          {label}
        </Button>

        <Popper style={{ zIndex: 10 }} id={id} open={open} anchorEl={anchorEl} transition>
          <Card className={classes.root}>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {contentHeader}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {content}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              {handleContinue && (
                <Button size="small" color="primary" onClick={handleContinue}>
                  Continue
                </Button>
              )}
            </CardActions>
          </Card>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

TeachingBubble.defaultProps = {
  capabilitySlug: undefined,
  handleContinue: undefined,
};

export default TeachingBubble;
