import React, { useState, useEffect } from 'react';
import NewWindow from 'react-new-window';
import { isMobile } from 'react-device-detect';

// Material UI
import CssBaseline from '@material-ui/core/CssBaseline';
import { Grid, CircularProgress, useTheme } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';

// Custom Components
import Header from './components/header.component';
import Footer from './components/footer.component';
import SideBar from './components/sidebar/sidebar.component';
import PageRouter from './components/page-router.component';

function App() {
  const [isAppLoading, setIsAppLoading] = useState(true);
  const [sidebarStatus, setSidebarStatus] = useState('closed');

  const theme = useTheme();

  useEffect(() => {
    // arbitrary wait for Realm Auth to initiate
    setTimeout(() => {
      setIsAppLoading(false);
    }, 1000);
  }, []);

  const toggleSidebar = () => {
    if (isMobile) {
      setSidebarStatus(sidebarStatus === 'popout' ? 'closed' : 'popout');
    } else {
      setSidebarStatus(sidebarStatus === 'open' ? 'closed' : 'open');
    }
  };

  return (
    <div
      className="App"
      style={{ backgroundColor: theme.palette.background.default }}
    >
      <CssBaseline />
      <Grid
        style={{ height: '100vh', width: '100%', marginLeft: '0px' }}
        container
        spacing={2}
      >
        <Grid
          item
          container
          xs
          sm
          direction="column"
          spacing={2}
          justify="space-between"
          style={{ marginLeft: '0px' }}
        >
          <Grid item container>
            <Grid item sm={12}>
              <Header toggleSidebar={toggleSidebar} />
            </Grid>
          </Grid>
          <Grid item container xs>
            <Grid item container justify="center">
              {isAppLoading ? <CircularProgress /> : <PageRouter />}
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item sm={12} style={{ width: 'inherit' }}>
              <Footer />
            </Grid>
          </Grid>
        </Grid>
        <Slide
          direction="left"
          in={sidebarStatus === 'open'}
          mountOnEnter
          unmountOnExit
        >
          <Grid
            item
            xs={12}
            lg={4}
            style={{
              backgroundColor:
                theme.palette.type === 'light'
                  ? theme.palette.grey[200]
                  : theme.palette.grey[900],
            }}
          >
            <SideBar {...{ setSidebarStatus, sidebarStatus }} />
          </Grid>
        </Slide>
      </Grid>
      {sidebarStatus === 'popout' && (
        <NewWindow title="mSIDE">
          <div
            style={{
              backgroundColor:
                theme.palette.type === 'light'
                  ? theme.palette.grey[200]
                  : theme.palette.grey[900],
              height: '100vh',
              padding: 10,
            }}
          >
            <SideBar {...{ setSidebarStatus, sidebarStatus }} />
          </div>
        </NewWindow>
      )}
    </div>
  );
}

export default App;
