import { createMuiTheme, PaletteType } from '@material-ui/core';

const akzidenzGrotesk = {
  fontFamily: 'AkzidenzGrotesk',
  src: `
    url('//db.onlinewebfonts.com/t/53c0b5ca4d4f261f0b4bacba005484ef.eot'), 
    url('//db.onlinewebfonts.com/t/53c0b5ca4d4f261f0b4bacba005484ef.eot?#iefix') format('embedded-opentype'), 
    url('//db.onlinewebfonts.com/t/53c0b5ca4d4f261f0b4bacba005484ef.woff2') format('woff2'), 
    url('//db.onlinewebfonts.com/t/53c0b5ca4d4f261f0b4bacba005484ef.woff') format('woff'), 
    url('//db.onlinewebfonts.com/t/53c0b5ca4d4f261f0b4bacba005484ef.ttf') format('truetype'), 
    url('//db.onlinewebfonts.com/t/53c0b5ca4d4f261f0b4bacba005484ef.svg#AkzidenzGrotesk') format('svg');
  `,
};

export const lightTheme = createMuiTheme({
  palette: {
    type: 'light' as PaletteType,
    primary: {
      main: '#13AA52',
    },
    secondary: {
      main: '#13AA52',
    },
  },
  typography: {
    fontFamily: 'AkzidenzGrotesk',
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [akzidenzGrotesk],
      },
    },
  },
});

export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark' as PaletteType,
    primary: {
      main: '#13AA52',
    },
    secondary: {
      main: '#13AA52',
    },
  },
  typography: {
    fontFamily: 'AkzidenzGrotesk',
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [akzidenzGrotesk],
      },
    },
  },
});
