import React from 'react';

interface CapabilityContext {
  currentCapability: string;
  setCurrentCapability: React.Dispatch<React.SetStateAction<string>>;
}

const INITIAL_VALUE: string = '/';
const CapabilityContext = React.createContext<CapabilityContext>({
  currentCapability: INITIAL_VALUE,
  setCurrentCapability: () => {},
});

// Custom React Hook for Component Consumption
export function useCapabilityContext() {
  const context = React.useContext(CapabilityContext);
  if (!context) {
    throw new Error(
      'useCapabilityContext must be used within a CapabilityContextProvider'
    );
  }
  return context;
}

// Context for app level state
export const CapabilityProvider: React.FC = (props) => {
  const [currentCapability, setCurrentCapability] = React.useState(
    INITIAL_VALUE
  );

  return (
    <CapabilityContext.Provider
      value={{ currentCapability, setCurrentCapability }}
    >
      {props.children}
    </CapabilityContext.Provider>
  );
};

CapabilityProvider.propTypes = {};
