import React, { useState, useEffect } from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { Link as RouterLink } from 'react-router-dom';
import ListingCard from './listing-card.component';
import ListingSearchSkeleton from './listing-search-skeleton.component';

// Interfaces
import { SearchResults } from '../../interfaces/search.interfaces';

interface Props {
  searchResults: SearchResults;
}

const ListingSearchResults = ({ searchResults }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);

  const { listings } = searchResults;

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [listings]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const limitResults = 5;
  const startItem = (page - 1) * limitResults;
  const endItem = Math.min(startItem + limitResults, listings.length);

  const getTotalPages = () => {
    const div = Math.trunc(listings.length / limitResults);
    const rem = listings.length % limitResults;
    if (rem > 0) {
      return div + 1;
    }
    return div;
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container direction="column" alignContent="center">
        {isLoading ? (
          <Typography align="center">Loading...</Typography>
        ) : (
          <Typography align="center">
            {listings.length > 0
              ? `Results: ${startItem + 1} - ${endItem} of ${listings.length}`
              : 'No Results Found! Please try a new search!'}
          </Typography>
        )}

        {listings.length > 0 && (
          <Pagination
            count={getTotalPages()}
            page={Math.min(page, getTotalPages())}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            hidden={isLoading}
          />
        )}
      </Grid>
      {isLoading ? (
        <>
          <Grid item>
            <ListingSearchSkeleton />
          </Grid>
          <Grid item>
            <ListingSearchSkeleton />
          </Grid>
          <Grid item>
            <ListingSearchSkeleton />
          </Grid>
          <Grid item>
            <ListingSearchSkeleton />
          </Grid>
          <Grid item>
            <ListingSearchSkeleton />
          </Grid>
        </>
      ) : (
        listings.slice(startItem, endItem).map((item, index) => (
          <Grid item key={index}>
            <Link
              underline="none"
              component={RouterLink}
              to={`/listing/${item._id}`}
            >
              <ListingCard
                pictureUrl={item.images.picture_url}
                name={item.name}
                cost={` $${item.priceDbl} `}
                bedrooms={` ${item.bedrooms} `}
                bathrooms={` ${item.bathDbl} `}
                accomodates={` ${item.accommodates} `}
              />
            </Link>
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default ListingSearchResults;
