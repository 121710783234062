import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Material UI
import { Grid, Button, Divider, useTheme } from '@material-ui/core';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';

// Custom Components
import AccountMenuButton from './account-menu-button.component';
import FeaturesMenuButton from './features-menu-button.component';

// Contexts
import { useRealmAuth } from '../providers/realm-auth.provider';

interface Props {
  toggleSidebar: () => void;
}

const Header = ({ toggleSidebar }: Props) => {
  const { currentUser } = useRealmAuth();

  const theme = useTheme();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container xs={12} justify="center">
        <Grid item>
          <img
            id="logo"
            src={
              theme.palette.type === 'light' ? '/logo.png' : '/logo_white.png'
            }
            height="48px"
            title="mongo Shared Integrated Demo Environment"
            alt="mSIDE"
          />
        </Grid>
      </Grid>
      {currentUser && (
        <Grid item container xs={12} justify="center" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<HomeOutlinedIcon />}
              component={RouterLink}
              to="/"
            >
              Home
            </Button>
          </Grid>
          {process.env.REACT_APP_ENABLE_CHARTS && (
            <Grid item className="tour-header-features">
              <FeaturesMenuButton />
            </Grid>
          )}
          <Grid item className="tour-header-user">
            <AccountMenuButton />
          </Grid>
          <Grid item className="tour-header-sidebar">
            <Button
              variant="contained"
              startIcon={<ChevronLeftOutlinedIcon />}
              color="primary"
              onClick={toggleSidebar}
            >
              Sidebar
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default Header;
