import React, { useState } from 'react';
import { CircularProgress, MenuItem, Grid } from '@material-ui/core';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import DropDownButton from '../dropdown-button.component';

interface Props {
  realmUserID: string;
  realmUserEmail: string;
  notebookLink: string;
}

const OpenWindow = (link: string) => {
  window.open(link, '_blank');
};

const NotebookButton: React.FC<Props> = ({
  realmUserID,
  realmUserEmail,
  notebookLink,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const containerPort = sessionStorage.getItem('containerPort') || '';

  const fixedEmail = realmUserEmail.replace(/\W/gi, '');

  const shellHost = process.env.REACT_APP_NOTEBOOK_URL;
  const SharedNotebookLink = `${shellHost}:8888/notebooks${notebookLink}?token=a63f5af6a3b7e503fd1e6ff1656701652cd974ca`;

  const deployPersonalNotebook = () => {
    if (containerPort.length > 0) {
      OpenWindow(
        `${shellHost}:${containerPort}/notebooks${notebookLink}?token=${realmUserID}`
      );
    } else {
      setIsLoading(true);
      fetch(
        `https://webhooks.mongodb-stitch.com/api/client/v2.0/app/mside-rqcve/service/spawnhub/incoming_webhook/redirectNewContainerRequest?id=${realmUserID}&email=${fixedEmail}`
      )
        .then((r) => r.json())
        .then((r) => {
          setIsLoading(false);
          sessionStorage.setItem('containerPort', `${r.port}`);
          OpenWindow(
            `${shellHost}:${r.port}/notebooks${notebookLink}?token=${realmUserID}`
          );
        })
        .catch((e) => {
          setIsLoading(false);
          // console.log(e);
        });
    }
  };

  return (
    <Grid item>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <DropDownButton
          label="Deploy Notebook"
          startIcon={<LibraryBooksOutlinedIcon />}
        >
          <MenuItem onClick={() => deployPersonalNotebook()}>
            Personal Notebook
          </MenuItem>
          <MenuItem onClick={() => OpenWindow(SharedNotebookLink)}>
            Shared Notebook
          </MenuItem>
        </DropDownButton>
      )}
    </Grid>
  );
};

export default NotebookButton;
