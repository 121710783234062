import React from 'react';

// Contexts

// Custom Components
import GoogleButton from 'react-google-button';

// Material UI
import { Grid } from '@material-ui/core';
import { useRealmAuth } from '../providers/realm-auth.provider';

const SignInAndSignUpPage = () => {
  const {
    actions: { handleLogin },
  } = useRealmAuth();

  return (
    <Grid item>
      <GoogleButton
        type="light"
        onClick={() => {
          handleLogin('google');
        }}
      />
    </Grid>
  );
};

export default SignInAndSignUpPage;
