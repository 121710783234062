import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

// Contexts
import { useRealmAuth } from '../providers/realm-auth.provider';
import { useCapabilityContext } from '../providers/capability.provider';

// Custom Components
import {
  ProtectedRoute,
  ProtectedRouteProps,
} from './protected-route.component';

// Pages
import HomePage from '../pages/homepage.component';
import SignInAndSignUpPage from '../pages/sign-in-and-sign-up.component';
import ChartsPage from '../pages/charts.component';
import ListingPage from '../pages/listing.component';
import BookingPage from '../pages/booking.component';
import MyBookingsPage from '../pages/mybookings.component';
import PageNotFound from '../pages/page-not-found.component';
import SettingsPage from '../pages/settings.component';
import NotebooksPage from '../pages/notebooks.component';

const PageRouter = () => {
  const { isLoggedIn } = useRealmAuth();

  const { setCurrentCapability } = useCapabilityContext();
  const location = useLocation().pathname;
  useEffect(() => {
    const s = location?.match(/(^\/\w*)/);
    if (s) {
      setCurrentCapability(s[0]);
    }
  }, [location, setCurrentCapability]);

  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated: isLoggedIn,
    isAllowed: isLoggedIn,
    authenticationPath: '/signin',
    restrictedPath: '/signin',
  };

  return (
    <Switch>
      <Route exact path="/signin" component={SignInAndSignUpPage} />
      <ProtectedRoute
        {...defaultProtectedRouteProps}
        exact
        path="/"
        component={HomePage}
      />
      <ProtectedRoute
        {...defaultProtectedRouteProps}
        exact
        path="/charts"
        component={ChartsPage}
      />
      <ProtectedRoute
        {...defaultProtectedRouteProps}
        exact
        path="/listing/:id?"
        component={ListingPage}
      />
      <ProtectedRoute
        {...defaultProtectedRouteProps}
        exact
        path="/booking/:id?"
        component={BookingPage}
      />
      <ProtectedRoute
        {...defaultProtectedRouteProps}
        exact
        path="/mybookings"
        component={MyBookingsPage}
      />
      <ProtectedRoute
        {...defaultProtectedRouteProps}
        exact
        path="/settings"
        component={SettingsPage}
      />
      <ProtectedRoute
        {...defaultProtectedRouteProps}
        exact
        path="/notebooks"
        component={NotebooksPage}
      />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default PageRouter;
