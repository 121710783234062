import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Material UI
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';

// Contexts
import { useAuditLog } from '../providers/audit-log.provider';
import { useRealmAuth } from '../providers/realm-auth.provider';

// Webhooks
import { getListingByID } from '../realm/webooks';

// Custom Components
import TeachingBubble from '../components/teaching-bubble.component';

const ListingPage = () => {
  const { id } = useParams();

  const { addLogEntry } = useAuditLog();
  useEffect(() => {
    addLogEntry({
      action: 'Page View',
      page: 'Listing Page',
      listing_id: id,
    });
  }, [addLogEntry, id]);

  const { currentUser } = useRealmAuth();

  const cc = localStorage.getItem('cc') || 'US';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [listingData, setListingData] = useState<any>({});

  useEffect(() => {
    getListingByID(currentUser?.id, { id, cc }).then((response) => {
      setListingData(response);
    });
  }, [id, currentUser, cc]);

  const history = useHistory();
  function handleRedirect(path: string) {
    history.push(path);
  }

  return (
    <Grid container>
      <Grid item xs={1} lg={2} />
      {listingData.name ? (
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          xs={10}
          lg={8}
          spacing={2}
        >
          <Grid item>
            <Typography variant="h4">{listingData.name}</Typography>
          </Grid>
          <Grid item>
            <img src={listingData.images.picture_url} alt="listing" />
          </Grid>
          <Grid item container justify="center" spacing={6}>
            <Grid item>
              <TeachingBubble
                label="Cost: $89"
                contentHeader="BSON Data Types"
                content="Did you know that we support multiple number types in the BSON spec?"
                capabilitySlug="/PLACEHOLDER_DECIMAL"
              />
            </Grid>
            <Grid item>
              <Button variant="contained">Beds: 1</Button>
            </Grid>
            <Grid item>
              <Button variant="contained">Baths: 1</Button>
            </Grid>
            <Grid item>
              <Button variant="contained">Sleeps: 1</Button>
            </Grid>
            <Grid item>
              <TeachingBubble
                label="Book Now"
                contentHeader="Flexible Schema"
                content="Did you know that different documents (here listings) can
            have different fields when stored in MongoDB? Like maybe
            some documents have a cleaning_fee and others do not."
                capabilitySlug="/PLACEHOLDER_FLEX"
                handleContinue={() =>
                  // eslint-disable-next-line no-underscore-dangle
                  handleRedirect(`/booking/${listingData._id}`)
                }
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h6">Description</Typography>
            <Typography>{listingData.description}</Typography>
            <br />
            <Typography variant="h6">Amenities</Typography>
            <ul>
              {listingData.amenities.map((amenity: string, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`amenities-${index}`}>{amenity}</li>
              ))}
            </ul>
            {listingData.house_rules && (
              <>
                <br />
                <Typography variant="h6">House Rules</Typography>
                <Typography>{listingData.house_rules}</Typography>
              </>
            )}
            {listingData.notes && (
              <>
                <br />
                <Typography variant="h6">Notes</Typography>
                <Typography>{listingData.notes}</Typography>
              </>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid item container xs={10} lg={8} justify="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      <Grid item xs={1} lg={2} />
    </Grid>
  );
};

export default ListingPage;
