import React, { useState, useCallback, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';
import { Link as RouterLink, useHistory } from 'react-router-dom';

// Material UI
import { useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  LinearProgress,
  Link,
  Button,
  MenuItem,
  MenuList,
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';

// Contexts
import { useRealmAuth } from '../../providers/realm-auth.provider';
import { useCapabilityContext } from '../../providers/capability.provider';
import { useAuditLog } from '../../providers/audit-log.provider';

// Webhooks
import { getSideBarStoryBySlug } from '../../realm/webooks';

// Custom Components
import SideBarExpansionPanel from './sidebar-expansion-panel.component';
import SideBarLinks from './sidebar-links.component';
import SideBarTour from '../sidebar-tour.component';

interface RealmData {
  name: string;
  Background: string;
  Talk_Track_Script: string;
  Procedure: string[];
  Tech_Involved: string[];
  ALTERNATESLUG: string[];
  Original_Source: string;
  Notebook_link: string;
}

interface Props {
  sidebarStatus: string;
  setSidebarStatus: React.Dispatch<React.SetStateAction<string>>;
}

const formatContent = (content: string) => {
  return ReactHtmlParser(
    content
      .replace(/\n\n/g, '<br /><br />')
      .replace(/<a href/g, '<a style="color: #13AA52;" href')
  );
};

const SideBar = ({ setSidebarStatus, sidebarStatus }: Props) => {
  const { addLogEntry } = useAuditLog();

  const { currentUser } = useRealmAuth();
  const { currentCapability } = useCapabilityContext();
  const theme = useTheme();
  const [data, setData] = useState<RealmData | null>(null);

  const [sidebarLoading, setSidebarLoading] = useState(false);

  const history = useHistory();
  function handleRedirect(path: string) {
    if (
      !path.startsWith('/PLACEHOLDER') &&
      !history.location.pathname.startsWith(path)
    ) {
      history.push(path);
    }
  }

  const toggleSidebar = () => {
    if (isMobile) {
      setSidebarStatus(sidebarStatus === 'popout' ? 'closed' : 'popout');
    } else {
      setSidebarStatus(sidebarStatus === 'open' ? 'popout' : 'open');
    }
  };

  const loadPanel = (slug: string) => {
    setSidebarLoading(true);
    getSideBarStoryBySlug(currentUser?.id, slug)
      .then((response) => {
        setData(response);
        setSidebarLoading(false);
        handleRedirect(slug);
      })
      .catch(() => {
        setData(null);
        setSidebarLoading(false);
      });

    addLogEntry({
      action: 'View Sidebar',
      slug,
    });
  };

  const loadPanelCallback = useCallback(loadPanel, [
    currentUser?.id,
    addLogEntry,
  ]);

  useEffect(() => {
    if (currentCapability) {
      loadPanelCallback(currentCapability);
    }
  }, [currentCapability, loadPanelCallback]);

  const [expanded, setExpanded] = React.useState('Talk Track');

  return data?.name && !sidebarLoading ? (
    <Grid
      container
      direction="column"
      spacing={2}
      style={{
        backgroundColor:
          theme.palette.type === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[900],
      }}
      className="tour-sidebar-main"
    >
      <SideBarTour setExpanded={setExpanded} />
      <Grid item className="tour-sidebar-popout">
        <MenuList>
          <MenuItem style={{ padding: 0 }}>
            <Link onClick={toggleSidebar} color="inherit">
              <Typography variant="h4">
                {data?.name}{' '}
                {sidebarStatus === 'open' ? (
                  <LaunchIcon />
                ) : (
                  <LaunchIcon style={{ transform: 'rotate(180deg)' }} />
                )}
              </Typography>
            </Link>
          </MenuItem>
        </MenuList>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {formatContent(data.Background)}
        </Typography>
      </Grid>
      <Grid item>
        <SideBarExpansionPanel
          title="Talk Track"
          expanded={expanded}
          setExpanded={setExpanded}
          className="tour-sidebar-talk-track"
        >
          <Typography>{formatContent(data.Talk_Track_Script)}</Typography>
        </SideBarExpansionPanel>
        <SideBarExpansionPanel
          title="Technology"
          expanded={expanded}
          setExpanded={setExpanded}
          className="tour-sidebar-technology"
        >
          <ul>
            {data?.Tech_Involved.map((item, index) => {
              return <li key={`tech-${index}`}>{formatContent(item)}</li>;
            })}
          </ul>
        </SideBarExpansionPanel>
        <SideBarExpansionPanel
          title="Procedure"
          expanded={expanded}
          setExpanded={setExpanded}
          className="tour-sidebar-procedure"
        >
          <ul>
            {data?.Procedure.map((item, index) => {
              return <li key={`steps-${index}`}>{formatContent(item)}</li>;
            })}
          </ul>
        </SideBarExpansionPanel>
        <SideBarExpansionPanel
          title="Linked RCs"
          expanded={expanded}
          setExpanded={setExpanded}
          className="tour-sidebar-technology"
        >
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Typography>
                The following links take you to pages where related RCs are
                implemented.
              </Typography>
            </Grid>
            {data?.ALTERNATESLUG?.map((item, index) => {
              return (
                <Grid item>
                  <Button variant="contained" component={RouterLink} to={item}>
                    {item.slice(1).charAt(0).toUpperCase() + item.slice(2)}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </SideBarExpansionPanel>
        <SideBarExpansionPanel
          title="Links"
          expanded={expanded}
          setExpanded={setExpanded}
          className="tour-sidebar-links"
        >
          <SideBarLinks
            notebook_link={data.Notebook_link}
            pov_source_link={data.Original_Source}
          />
        </SideBarExpansionPanel>
      </Grid>
    </Grid>
  ) : (
    <Grid container justify="center">
      <LinearProgress style={{ width: '100%' }} />
      Loading...
    </Grid>
  );
};

export default SideBar;
