import React, { useMemo, useEffect, useState } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { isMobile } from 'react-device-detect';

// Material UI
import { useTheme } from '@material-ui/core/styles';
import { Grid, LinearProgress } from '@material-ui/core';

// Contexts
import { useAuditLog } from '../providers/audit-log.provider';

// Custom Components
import TeachingBubble from '../components/teaching-bubble.component';

const ChartsPage = () => {
  const { addLogEntry } = useAuditLog();
  useEffect(() => {
    addLogEntry({
      action: 'Page View',
      page: 'Charts Page',
    });
  }, [addLogEntry]);

  const theme = useTheme();
  const [isChartLoaded, setChartLoaded] = useState(false);

  const memoizedchartsValue = useMemo(
    () => [
      'd6752b71-0208-4ba5-8e52-7a9b266dfdeb',
      'a13527dd-1ad6-4671-9e0b-31b4b2cb9221',
      '7bbac4b0-16b9-4c3f-aec5-3869c699b391',
      'ff43e85d-debc-49ca-91dd-9a441d3ef90d',
    ],
    []
  );

  useEffect(() => {
    setChartLoaded(false);
    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-global_shared-jebkk',
    });

    memoizedchartsValue.forEach((chart) => {
      sdk
        .createChart({
          chartId: chart,
          theme: theme.palette.type,
          background: theme.palette.background.paper,
        })
        .render(document.getElementById(chart)!)
        .then(() => setChartLoaded(true));
    });
  }, [memoizedchartsValue, theme]);

  return (
    <Grid container>
      <Grid item xs={2} />
      <Grid
        item
        xs={isMobile ? 12 : 8}
        container
        direction="column"
        spacing={2}
        style={{ marginLeft: 0 }}
      >
        <Grid item container spacing={2} justify="center">
          <Grid item>
            <TeachingBubble
              label="Performance Impact?"
              contentHeader="Workload Isolation"
              content="Did you know that you can tag clusters to only answer to analytical queries in MongoDB, thus not affecting the main nodes?"
              capabilitySlug="/PLACEHOLDER_WORKLOADISO"
            />
          </Grid>
          <Grid item>
            <TeachingBubble
              label="BI Tools?"
              contentHeader="MongoDB BI Connector"
              content="Did you know that the MongoDB BI Connector can translate SQL queries into MQL queries, allowing tools like Tableau and PowerBI to connect to MongoDB Atlas?"
              capabilitySlug="/PLACEHOLDER_SQL"
            />
          </Grid>
        </Grid>
        <Grid item>{!isChartLoaded && <LinearProgress />}</Grid>
        {memoizedchartsValue.map((chart, index) => (
          <Grid key={index} item>
            <div id={chart} style={{ width: '100%', height: 500 }} />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};

export default ChartsPage;
