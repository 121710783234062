const baseURL = `https://webhooks.mongodb-stitch.com/api/client/v2.0/app/${process.env.REACT_APP_REALM_APP_NM}/service/http/incoming_webhook`;

// eslint-disable-next-line @typescript-eslint/naming-convention
type uid = string | undefined;

export const createAuditLogEntry = (uid: uid, data: object) => {
  // console.log("Audit: ", data);
  return fetchHelper(`${baseURL}/audit`, uid, data);
};

export const createBooking = (uid: uid, data: object) => {
  return fetchHelper(`${baseURL}/createBooking`, uid, data);
};

export const deleteBookings = (uid: uid, data: object) => {
  return fetchHelper(`${baseURL}/deleteBookings`, uid, data);
};

export const getListingByID = (uid: uid, data: { id: string; cc: string }) => {
  return fetchHelper(
    `${baseURL}/getListingById?id=${data.id}&cc=${data.cc}`,
    uid
  );
};

export const getListingBySearchCriteria = (uid: uid, data: object) => {
  return fetchHelper(`${baseURL}/getListingsBySearchCriteria`, uid, data);
};

export const getSideBarStoryBySlug = (uid: uid, slug: string) => {
  return fetchHelper(`${baseURL}/getSideBarStoryBySlug?slug=${slug}`, uid);
};

export const getAllSideBarStories = (uid: uid) => {
  return fetchHelper(`${baseURL}/getAllSideBarStories`, uid);
};

export const getPOVList = (uid: uid) => {
  return fetchHelper(`${baseURL}/getPOVList`, uid);
};

export const getLastTwentyBookingsByUserId = (uid: uid) => {
  return fetchHelper(
    `${baseURL}/getLastTwentyBookingsByUserId?userid=${uid}`,
    uid
  );
};

export const setCustomUserData = (uid: uid, data: object) => {
  return fetchHelper(`${baseURL}/setCustomUserData`, uid, data);
};

export const testWebhook = (uid: uid) => {
  return fetchHelper(`${baseURL}/testAuthenticatedWebhook`, uid);
};

/// Helper Functions
const fetchHelper = (url: string, uid: uid, data?: object) => {
  const params = {
    method: data ? 'post' : 'get',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      ...(uid && { Authorization: uid }),
    },
    ...(data && { body: JSON.stringify(data) }),
  };

  return fetch(url, params).then((response) => response.json());
};
