import React, { useReducer, useEffect } from 'react';
import JoyRide, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useTheme, Typography, Link } from '@material-ui/core';

const TOUR_STEPS = [
  {
    target: 'body',
    title: 'Welcome to mSIDE!',
    content:
      'Brought to you by a team of dedicated and passionate MongoDB Solution Architects! With the vision of providing a one-stop-shop for all of your demonstration needs.',
    placement: 'center',
    hideCloseButton: true,
  },
  {
    target: '.tour-home-search-features',
    title: 'Main Search Bar',
    content:
      'Rich Data Types, Geospatial Queries, and Atlas Search are just a few key features here.',
    disableBeacon: true,
    hideCloseButton: true,
  },
  {
    target: '.tour-map',
    title: 'Mapped Results',
    content:
      'Results containing Geospatial Coordinates are rendered to the map. Clicking the mongodb icons for more information.',
    placement: 'right',
    hideCloseButton: true,
  },
  {
    target: '.tour-results',
    title: 'Listed Results',
    content:
      'All results returned from the search query are displayed here. Use the main search bar to update these results.',
    placement: 'left',
    hideCloseButton: true,
  },
  {
    target: '.tour-header-features',
    title: 'Additional Features',
    content: (
      <>
        <Typography>
          <b>Charts:</b> Embeded Charts
        </Typography>
        <Typography>
          <b>Notebooks:</b> Bookmarkable Page!
        </Typography>
      </>
    ),
  },
  {
    target: '.tour-header-user',
    title: 'User Pages',
    content: (
      <Typography>
        Leveraging Realm's{' '}
        <Link
          rel="noopener noreferrer"
          href="https://docs.mongodb.com/realm/users/enable-custom-user-data/"
          target="_blank"
        >
          Custom User Data
        </Link>
        , adjust your preferences in the settings page.
      </Typography>
    ),
    hideCloseButton: true,
  },
  {
    target: '.tour-header-sidebar',
    title: 'Self Guided Demos',
    content:
      'The sidebar is a tool available to you to help with the process of demoing the many features of mSIDE. Click the Sidebar Button to learn more!',
    hideCloseButton: true,
  },
];

const INITIAL_STATE = {
  key: new Date(), // This field makes the tour to re-render when we restart the tour
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
};

// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case 'START':
      return { ...state, run: true };
    case 'RESET':
      return { ...state, stepIndex: 0 };
    case 'STOP':
      return { ...state, run: false };
    case 'NEXT_OR_PREV':
      return { ...state, ...action.payload };
    case 'RESTART':
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};

// Tour component
const Tour = () => {
  const theme = useTheme();

  // Tour state is the state which control the JoyRide component
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    // Auto start the tour if the tour is not viewed before
    if (!localStorage.getItem('mainTour')) {
      dispatch({ type: 'START' });
    }
  }, []);

  // Set once tour is viewed, skipped or closed
  const setTourViewed = () => {
    localStorage.setItem('mainTour', '1');
  };

  const callback = (data: any) => {
    const { action, index, type, status } = data;

    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: 'STOP' });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.
      dispatch({
        type: 'NEXT_OR_PREV',
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  //   const startTour = () => {
  //     // Start the tour manually
  //     dispatch({ type: 'RESTART' });
  //   };

  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        showProgress={true}
        showSkipButton={true}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        styles={{
          tooltipContainer: {
            textAlign: 'left',
          },
          buttonBack: {
            marginRight: 10,
          },
          tooltipTitle: {
            textAlign: 'center',
          },
          options: {
            textColor: theme.palette.text.primary,
            primaryColor: theme.palette.primary.main,
            arrowColor: theme.palette.background.paper,
            backgroundColor: theme.palette.background.paper,
          },
        }}
        locale={{
          last: 'End tour',
        }}
      />
    </>
  );
};

export default Tour;
