import React, { useEffect, useState } from 'react';

// Material UI
import KeyboardOutlinedIcon from '@material-ui/icons/KeyboardOutlined';
import CodeIcon from '@material-ui/icons/Code';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';

// Webhooks
import { Grid, Button, MenuItem } from '@material-ui/core';
import { getPOVList } from '../../realm/webooks';

// Custom Components
import DropDownButton from '../dropdown-button.component';
import { useRealmAuth } from '../../providers/realm-auth.provider';
import { useCapabilityContext } from '../../providers/capability.provider';
import NotebookButton from './notebook-button.component';

interface POV {
  name: string;
  Slug: string;
}

interface Props {
  notebook_link: string;
  pov_source_link: string;
}

const SideBarLinks: React.FC<Props> = ({ notebook_link, pov_source_link }) => {
  const { currentUser } = useRealmAuth();
  const { setCurrentCapability } = useCapabilityContext();
  const [capabilities, setCapabilities] = useState<Array<POV>>([]);

  useEffect(() => {
    getPOVList(currentUser?.id)
      .then((response) => {
        setCapabilities(response.povlist);
      })
      .catch(() => setCapabilities([]));
  }, [currentUser]);

  const shellHost = process.env.REACT_APP_WEBSHELL_URL;
  const shellParams = '/?hostname=172.31.31.109&port=2222';
  const additionalParams =
    '&password=bW9uZ29tb25nb21vbmdv&title=MongoDB%20Web%20Shell&command=use%20mside';
  const MongoLink = `${shellHost}:9666${shellParams}&username=sa${additionalParams}`;
  const MongoFederatedLink = `${shellHost}:9666${shellParams}&username=safed${additionalParams}`;
  const MongoshLink = `${shellHost}:9666${shellParams}&username=samongosh${additionalParams}`;
  const MongoshFederatedLink = `${shellHost}:9666${shellParams}&username=samongoshfed${additionalParams}`;
  const MongosqlLink = `${shellHost}:9666${shellParams}&username=sql&password=bW9uZ29tb25nb21vbmdv&title=MongoDB%20BI%20Connector`;

  const OpenWindow = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item>
        <NotebookButton
          realmUserEmail={currentUser?.profile.email || ''}
          realmUserID={currentUser?.id || ''}
          notebookLink={notebook_link}
        />
      </Grid>
      <Grid item>
        <DropDownButton
          label="Open Webshell"
          startIcon={<KeyboardOutlinedIcon />}
        >
          <MenuItem onClick={() => OpenWindow(MongoLink)}>mongo</MenuItem>
          <MenuItem onClick={() => OpenWindow(MongoFederatedLink)}>
            mongo (Federated){' '}
          </MenuItem>
          <MenuItem onClick={() => OpenWindow(MongoshLink)}>mongosh</MenuItem>
          <MenuItem onClick={() => OpenWindow(MongoshFederatedLink)}>
            mongosh (Federated){' '}
          </MenuItem>
          <MenuItem onClick={() => OpenWindow(MongosqlLink)}>mongosql</MenuItem>
        </DropDownButton>
      </Grid>
      <Grid item>
        <DropDownButton
          label="Connect to cluster"
          startIcon={<PublicOutlinedIcon />}
        >
          <MenuItem
            onClick={() =>
              navigator.clipboard.writeText(
                'mongo "mongodb+srv://sa:sa@demo.akkzw.mongodb.net/mside"'
              )
            }
          >
            Copy Mongo Shell Connection String
          </MenuItem>
          <MenuItem
            onClick={() =>
              OpenWindow(
                'https://cloud.mongodb.com/v2/5ead7a92758e00636bc31452#clusters'
              )
            }
          >
            Open Atlas Project
          </MenuItem>
        </DropDownButton>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          startIcon={<CodeIcon />}
          onClick={() => OpenWindow(pov_source_link)}
        >
          POV Source
        </Button>
      </Grid>
      <Grid item>
        <DropDownButton
          label="Capability List"
          startIcon={<ListAltOutlinedIcon />}
        >
          {capabilities.map((item, index) => {
            return (
              <MenuItem
                key={`cap-${index}`}
                onClick={() => setCurrentCapability(item.Slug)}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </DropDownButton>
      </Grid>
    </Grid>
  );
};

export default SideBarLinks;
