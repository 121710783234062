import React, { useState, useEffect } from 'react';
import { Grid, Hidden } from '@material-ui/core';

// Custom Components
import SearchBar from '../components/searchbar/searchbar.component';
import ListingMap from '../components/map.component';
import ListingSearchResults from '../components/listing/listing-search-results.component';

// Interfaces
import { SearchResults } from '../interfaces/search.interfaces';
import { useAuditLog } from '../providers/audit-log.provider';
import Tour from '../components/tour.component';

const HomePage = () => {
  const { addLogEntry } = useAuditLog();
  useEffect(() => {
    addLogEntry({
      action: 'Page View',
      page: 'Home Page',
    });
  }, [addLogEntry]);

  const [searchResults, setSearchResults] = useState<SearchResults>({
    mapData: {
      title: '',
      position: {
        lat: parseFloat(
          localStorage.getItem('selectedLocationLat') || '40.71455'
        ),
        lng: parseFloat(
          localStorage.getItem('selectedLocationLng') || '-74.00714'
        ),
      },
      cc: '',
    },
    listings: [],
  });

  return (
    <Grid container spacing={2} direction="column" className="">
      <Tour />
      <Grid item>
        <SearchBar {...{ setSearchResults }} />
      </Grid>
      <Grid
        item
        container
        direction="row"
        spacing={2}
        justify="center"
        xs
        style={{ marginLeft: '0px' }}
      >
        <Hidden smDown>
          <Grid item xs style={{ marginLeft: '8px' }} className="tour-map">
            <ListingMap {...{ searchResults }} />
          </Grid>
        </Hidden>
        <Grid item style={{ width: 490 }} className="tour-results">
          <ListingSearchResults {...{ searchResults }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomePage;
