import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { RealmAuthProvider } from './providers/realm-auth.provider';
import { AuditLogProvider } from './providers/audit-log.provider';
import { CapabilityProvider } from './providers/capability.provider';
import CustomThemeProvider from './providers/custom-theme.provider';

ReactDOM.render(
  <CustomThemeProvider>
    <RealmAuthProvider>
      <AuditLogProvider>
        <CapabilityProvider>
          <SnackbarProvider maxSnack={3} autoHideDuration={1500}>
            <Router>
              <App />
            </Router>
          </SnackbarProvider>
        </CapabilityProvider>
      </AuditLogProvider>
    </RealmAuthProvider>
  </CustomThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
