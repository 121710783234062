import React from 'react';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useCapabilityContext } from '../../providers/capability.provider';

const filter = createFilterOptions<string>();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 250,
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
    },
  })
);

interface Props {
  setSelectedKeywords: React.Dispatch<React.SetStateAction<string[]>>;
}

const SearchBarAutoCompleteTags = ({ setSelectedKeywords }: Props) => {
  const classes = useStyles();
  const { setCurrentCapability } = useCapabilityContext();

  return (
    <Autocomplete
      className={classes.root}
      multiple
      id="tags-outlined"
      options={amenities}
      getOptionLabel={(option) => option}
      limitTags={2}
      onChange={(event, newValue) => {
        setSelectedKeywords(newValue);
        setCurrentCapability('/PLACEHOLDER_TEXTSEARCH');
      }}
      filterSelectedOptions
      freeSolo
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push(params.inputValue);
        }

        return filtered;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Keyword Search"
          placeholder="Amenities"
        />
      )}
    />
  );
};

export default SearchBarAutoCompleteTags;

const amenities = [
  'Kitchen',
  'Heating',
  'Washer',
  'Dryer',
  'WiFi',
  'Fireplace',
  'Iron',
  'Laptop-Friendly Workspace',
  'Crib',
  'Self-Checkin',
  'Shampoo',
  'Air Conditioning',
  'Breakfast',
  'Hangers',
  'Hair Dryer',
  'TV',
  'Private Bathroom',
  'Free Parking',
  'Hot Tub',
  'Gym',
  'Pool',
  'Pets Allowed',
];
