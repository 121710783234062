import React from 'react';

// Material UI
import Grid from '@material-ui/core/Grid';
import GitHubIcon from '@material-ui/icons/GitHub';
import { Typography, Divider, Link } from '@material-ui/core';

interface Props {}

const Footer: React.FC<Props> = ({ children }) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Divider />
      </Grid>
      {/* <Grid item container xs={12} justify="center">
        <Grid item>{children}</Grid>
      </Grid> */}
      <Grid item container xs={12} justify="center" spacing={1}>
        <Grid item>
          <GitHubIcon color="primary" />
        </Grid>
        <Grid item>
          <Link
            href="https://github.com/10gen/presales-mside"
            target="_blank"
            rel="noopener"
          >
            <Typography color="primary">Check this out on GitHub</Typography>
          </Link>
        </Grid>
      </Grid>
      <Grid item container xs={12} justify="center">
        <Grid item>
          <Typography>
            Last Build: {process.env.REACT_APP_BUILDTIME || 'Today'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
