import React, { useEffect } from 'react';
import { useAuditLog } from '../providers/audit-log.provider';

const PageNotFound = () => {
  const { addLogEntry } = useAuditLog();
  useEffect(() => {
    addLogEntry({
      action: 'Page View',
      page: '404 Page',
    });
  }, [addLogEntry]);
  return <div>404!!</div>;
};

export default PageNotFound;
